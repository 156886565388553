<template>
  <div>
    <div class="alert">
      <div class="left">
        <span class="badge">15</span>
        <span class="title"
          >Получите 15 бонусных монет, абсолютно бесплатно !</span
        >
      </div>
      <button>
        Получить
        <img alt="Получить" src="@/assets/icons/profile/bonuses-arrow-up.svg" />
      </button>
    </div>

    <div class="main-title">
      <img alt="required" src="@/assets/icons/profile/profile-required.svg" /> -
      Главное задание
    </div>

    <transition-group name="fade" tag="div">
      <div v-for="(bonus, index) in bonuses" :key="bonus.id" class="task">
        <div class="left">
          <!-- <img
          alt="Telegram"
          class="icon"
          src="@/assets/icons/profile/bonuses-telegram.png"
        /> -->
          <img
            v-if="bonus.name === 'vk_full'"
            :alt="bonus.label"
            class="icon"
            src="@/assets/icons/profile/vk.svg"
          />
          <img
            v-else-if="bonus.name === 'email'"
            :alt="bonus.label"
            class="icon"
            src="@/assets/icons/profile/mail.svg"
          />
          <img
            v-else-if="bonus.name === 'tg_group_sub'"
            alt="Telegram"
            class="icon"
            src="@/assets/icons/profile/bonuses-telegram.png"
          />
          <div class="info">
            <div class="title">
              <span>{{ bonus.label }} </span>
              <img
                v-if="bonus.category === 'main'"
                alt="required"
                src="@/assets/icons/profile/profile-required.svg"
              />
            </div>
            <div class="amount">
              <img
                class="coin"
                alt="coin"
                src="@/assets/icons/header-coin.png"
              />
              {{ convertAmount(bonus.amount, bonus.currency) }}
              {{ icons[bonus.currency] }}
            </div>
          </div>
        </div>
        <div class="right">
          <!-- <input type="text" placeholder="Ваш Email" /> -->

          <span v-if="bonus.meta.tg">
            <a
              :href="'https://t.me/' + bonus.meta.tg.group_sub_alias"
              target="_blank"
              class="success"
            >
              Подписаться
            </a>
          </span>

          <span v-for="(id, task) in bonus.meta.vk" :key="task">
            <a
              v-if="task === 'group_sub'"
              :href="'https://vk.com/public' + id"
              target="_blank"
              class="success"
            >
              Подписаться
            </a>
            <a
              v-else-if="task === 'group_repost'"
              :href="'https://vk.com/wall' + id"
              target="_blank"
              class="success"
            >
              Репост
            </a>
          </span>
          <!-- <a class="success">Подписаться</a>
        <a class="success">Репост</a>
        <a class="success">Разрешить уведомления</a> -->

          <button class="primary" @click="check(index)">Получить</button>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      bonuses: [],
    };
  },
  computed: {
    ...mapGetters("user", ["convertAmount"]),
    ...mapState("user", ["icons"]),
  },
  async created() {
    try {
      let { data } = await this.$http.get("bonus/bonuses/init");
      this.bonuses = data.response;
    } catch (error) {
      this.$toast.error(error?.message || "Ошибка отправки запроса");
    }
  },
  methods: {
    async check(id) {
      try {
        let { name } = this.bonuses[id];
        let { data } = await this.$http.get("bonus/bonuses/check?name=" + name);
        if (data.error) return this.$toast.error(data.error);
        this.$store.commit("user/balance", data.response.balances);
        this.$toast.success("Вы успешно получили бонус");
        this.bonuses.splice(id, 1);
      } catch (error) {
        this.$toast.error(error?.message || "Ошибка отправки запроса");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  background: linear-gradient(90deg, #579824 0%, #a5e673 100%);
  border-radius: 6px;
  height: 90px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    .badge {
      margin: 0 25px;
      width: 45px;
      background-color: rgba(white, 0.35);
      height: 45px;
      box-shadow: 0 0 0 10px rgba(white, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: white;
      font-weight: 500;
      font-size: 18px;
    }
    .title {
      color: white;
      font-weight: 500;
      font-size: 16px;
    }
  }
  padding: 0 20px 0 10px;
  button {
    width: 140px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: transparent;
    border: 1px solid rgba(white, 0.45);
    color: white;
    font-size: 15px;
    transition: 200ms ease;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    img {
      margin-left: 10px;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: unset;
    padding: 20px;
    .left {
      .badge {
        display: none;
      }
    }
    button {
      display: block;
      width: 100%;
      margin-top: 10px;
    }
  }
}

.main-title {
  font-size: 14px;
  margin-bottom: 15px;
}

.task {
  display: flex;
  border: 1px solid #e7eef5;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    img.icon {
      margin-right: 10px;
      width: 55px;
    }
    .info {
      .amount {
        display: flex;
        align-items: center;
        color: #c6c9df;
        font-size: 15px;
        img {
          margin-right: 5px;
          width: 20px;
          object-fit: contain;
        }
      }
      .title {
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
  }
  .right {
    input {
      background: #ebf2f9;
      border-radius: 6px;
      padding: 15px 20px;
      margin-right: 10px;
      font-size: 15px;
      color: black;
      &::placeholder {
        color: #b3c0cd;
      }
    }
    button,
    a {
      border-radius: 6px;
      padding: 15px 25px;
      font-size: 15px;
      color: white;
      &.success {
        background-color: #81c84a;
        margin-right: 10px;
      }
      &.primary {
        background-color: #0085ff;
      }
      transition: 200ms ease;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    .right {
      margin-top: 15px;
      width: 100%;
      display: flex;
      a {
        display: block;
      }
    }
  }
}
</style>
